import React from 'react'

import NoteTypes from '../../components/views/settings/noteTypes/noteTypes'
import { LayoutLoggedIn } from '../../components/views/layout'

export default function NoteTypesPage() {
   return (
      <LayoutLoggedIn>
         <NoteTypes />
      </LayoutLoggedIn>
   )
}
