import React, { useState, useEffect, useMemo, useRef } from 'react'
import { TableWrapper } from '../../../common/table/TableWrapper'
import {
   PageHeader,
   Search,
   Modal,
   TrueOrFalse,
   CrudActions,
   Button,
   CrudActionButtons,
   SnackBar,
   Svg,
} from '../../../common'
import { Actions, PAGINATION, ACTION_TITLE } from '../../../../constants/tables'
import { Severity } from '../../../../types'
import * as tableStyles from '../../../common/table/table.module.scss'
import { CRUD_TABLE } from './constants'

const {
   extraClassName,
   primaryActionText,
   secondaryActionText,
   primaryDeleteText,
   modalTitle,
   placeholder,
   defaultActiveValue,
   defaultActionState,
   modalAddTitle,
   addButtonText,
   key,
   objectKey,
   objectKeyTitleCase,
   objectKeyNotificationCase,
   crudPaths,
   tableTitle,
   toggleTittle,
} = CRUD_TABLE

import { useCrudItems } from '../../../../hooks'

const NoteTypes = () => {
   const { query, skip, take } = PAGINATION
   const {
      data: crudData,
      get,
      update,
      add,
      remove,
      totalItems,
      setPath,
      isLoading,
      isCreating,
      isSuccessCreate,
      isErrorCreate,
      isUpdating,
      isSuccessUpdate,
      isErrorUpdate,
      isRemoving,
      isSuccessRemove,
      isErrorRemove,
   } = useCrudItems({ query, skip, take })

   const inputRef = useRef<HTMLInputElement>()

   const initialState = {
      disableAutoClose: false,
      message: '',
      severity: Severity.SUCCESS,
   }

   const [searchMode, setSearchMode] = useState(false)
   const [pageSized, setPageSized] = useState(PAGINATION.pageSized)
   const [isPageReset] = useState(false)
   const [actionState, setActionState] = useState(defaultActionState)
   const [isModalVisible, setIsModalVisible] = useState(false)
   const [activeValue, setActiveValue] = useState(defaultActiveValue)
   const [searchCriteria, setSearchCriteria] = useState('')
   const [open, setOpen] = useState(false)
   const [state, setState] = useState(initialState)
   const [toggleValue, setToggleValue] = useState(true)

   const handleGetItems = (query?: string, skip?: number, take?: number) => {
      get(query, skip, take)
   }

   const handleSearch = (query?: string, skip?: number, take?: number) => {
      get(query, skip, take)
   }

   const updateItem = (obj) => {
      if (obj.objectKey !== '') {
         update(obj)
      }
   }

   const addItem = (obj) => {
      add(obj)
   }

   const removeItem = (id) => {
      remove(id)
   }

   const handleClose = () => {
      setIsModalVisible(false)
      setActiveValue(defaultActiveValue)
      inputRef.current.value = ''
   }

   const showActionForm = (action, id?: string) => {
      if (action === Actions.Delete || action === Actions.Edit) {
         setActiveValue({
            id,
            title: crudData.find((item) => item.Id === id)?.[
               objectKeyTitleCase
            ],
            sendNotification: crudData.find((item) => item.Id === id)?.[
               objectKeyNotificationCase
            ],
            DueDateReminder: true,
         })
      }
      setActionState(action)
      setToggleValue(
         crudData.find((item) => item.Id === id)?.[objectKeyNotificationCase]
      )
      setIsModalVisible(true)
   }

   const handleActionForm = (action, id?, value?) => {
      switch (action) {
         case Actions.Add:
            addItem({
               [objectKey]: value,
               SendNotification: toggleValue,
            })
            break
         case Actions.Edit:
            updateItem({
               id,
               [objectKey]: value,
               SendNotification: toggleValue,
            })
            break
         case Actions.Delete:
            removeItem({
               id,
            })
            break
      }
      setIsModalVisible(false)
   }

   const columns = useMemo(
      () => [
         {
            Header: 'Actions',
            accessor: 'Id',
            Cell: (cell) => (
               <>
                  <CrudActionButtons
                     cell={cell}
                     action={showActionForm}
                     editAction
                     deleteAction
                  />
               </>
            ),
         },
         {
            Header: 'Title',
            accessor: 'Title',
            Cell: (cell: { row: { original: { Title: string } } }) => (
               <div className={tableStyles.tableColumnCellWrapper}>
                  {cell.row.original.Title}
               </div>
            ),
            width: 1000,
            minWidth: 20,
         },
         {
            Header: 'Creation Notification',
            accessor: 'SendNotification',
            align: 'center',
            minWidth: 130,
            Cell: ({ row }) => (
               <TrueOrFalse status={row.original.SendNotification} />
            ),
         },
         {
            Header: 'Due Date Reminder',
            accessor: 'DueDateReminder',
            align: 'center',
            minWidth: 130,
            Cell: ({ row }) => (
               <TrueOrFalse status={row.original.DueDateReminder} />
            ),
         },
      ],
      [crudData]
   )

   useEffect(() => {
      setPath(crudPaths, key)
   }, [])

   function handleMessage(message, severity) {
      setState({ ...state, message, severity })
   }

   // handle actions
   useEffect(() => {
      handleMessage('Note Type has been updated.', Severity.SUCCESS)
   }, [isSuccessUpdate])

   useEffect(() => {
      handleMessage('Note Type has been created.', Severity.SUCCESS)
   }, [isSuccessCreate])

   useEffect(() => {
      handleMessage('Note Type has been removed.', Severity.SUCCESS)
   }, [isSuccessRemove])

   // handle errors
   useEffect(() => {
      handleMessage(
         'Creating a note type has been unsuccessful.',
         Severity.ERROR
      )
   }, [isErrorCreate])

   useEffect(() => {
      handleMessage(
         'Updating a note type has been unsuccessful.',
         Severity.ERROR
      )
   }, [isErrorUpdate])

   useEffect(() => {
      handleMessage(
         'Deleting a note type has been unsuccessful.',
         Severity.ERROR
      )
   }, [isErrorRemove])
   // set into one varible for snackbar bool
   const showSnackbar =
      isSuccessUpdate ||
      isSuccessCreate ||
      isSuccessRemove ||
      isErrorCreate ||
      isErrorUpdate ||
      isErrorRemove

   const handleToggle = (_id: string, isChecked: boolean) =>
      setToggleValue(isChecked)

   return (
      <div>
         <SnackBar
            message={state.message}
            open={showSnackbar}
            onClose={() => setOpen(false)}
            severity={state.severity}
            disableAutoClose={state.disableAutoClose}
         />
         <PageHeader title={tableTitle}>
            <Button
               onClick={() => showActionForm(Actions.Add, '')}
               preserveText
               variant="plain"
            >
               <Svg id="plus" /> {addButtonText}
            </Button>
         </PageHeader>
         <Search
            handleQuery={handleSearch}
            searchMode={searchMode}
            setSearchMode={setSearchMode}
            pageSized={pageSized}
            placeHolder={placeholder}
            setSearchCriteria={setSearchCriteria}
         />
         <TableWrapper
            isLoading={isLoading}
            data={crudData}
            columns={columns}
            totalCount={totalItems}
            getItems={handleGetItems}
            takeItems={PAGINATION.take}
            skipItems={PAGINATION.skip}
            setSearchMode={setSearchMode}
            searchMode={searchMode}
            query={query}
            isPageReset={isPageReset}
            setPageSized={setPageSized}
            extraClassName={extraClassName}
            searchCriteria={searchCriteria}
         />
         <Modal
            isModalVisible={isModalVisible}
            closeModal={handleClose}
            title={ACTION_TITLE[actionState] + modalTitle}
         >
            <CrudActions
               actionState={actionState}
               primaryActionText={primaryActionText}
               secondaryActionText={secondaryActionText}
               primaryDeleteText={primaryDeleteText}
               id={activeValue.id}
               name={activeValue.title}
               modalAddTitle={modalAddTitle}
               handleAction={handleActionForm}
               handleClose={handleClose}
               handleToggle={handleToggle}
               toggleValue={toggleValue}
               toggleTittle={toggleTittle}
               toggleLabel={
                  'Enable this option to send notification every time a note is created.'
               }
               inputRef={inputRef}
               valueName="Title"
            />
         </Modal>
      </div>
   )
}

export default NoteTypes
