import { PATH } from '../../../../constants/global'
import { TABLE, CRUD } from '../../../../constants/tables'

export const CRUD_TABLE = {
    extraClassName: 'table-actions',
    params: '?noteTypesId=',
    modalTitle: ' Note Type',
    placeholder: ' note types',
    addButtonText: 'Add Note Types',
    modalAddTitle: 'Title',
    key: 'Items',
    defaultActiveValue: {
        id: '',
        title: '',
        sendNotification: false,
        DueDateReminder: true
    },
    defaultActionState: 0,
    objectKey: 'title',
    objectKeyTitleCase: 'Title',
    objectKeyNotificationCase: 'SendNotification',
    primaryActionText: CRUD.primaryActionText,
    secondaryActionText: CRUD.secondaryActionText,
    primaryDeleteText: CRUD.primaryDeleteText,
    crudPaths: PATH.NOTETYPES,
    tableTitle: TABLE.TITLE.NOTETYPES,
    header: 'Title',
    toggleTittle: 'Creation Notification'
}